import React, { useState, useEffect } from 'react';
import { Header, Button, Modal, Segment, Label } from 'semantic-ui-react';
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react';
import ChoiceList from './choiceList'
import { toFixed } from '../../utils/ultils'

const MenuItem = (props) => {
  const { itemName, price, description, choices, basket, chinese } = props;
  const [showModal, setShowModal] = useState(false);
  const [exsitingQty, setExsitingQty] = useState(basket.getItemQuantity(itemName));

  useEffect(() => {
    setExsitingQty(basket.getItemQuantity(itemName));
  });
  const [qty, setQty] = useState(exsitingQty > 0 ? exsitingQty : 1);

  const closeModal = () => {
    setShowModal(false);
    basket.clearSelectedItems();
  }

  const addItem = () => {
    basket.clearSelectedItems();
    setShowModal(true);

  }

  const addComplexItem = () => {
    basket.addSelectedItemsToBasket({ itemName, chinese, price: basket.selectItemPriceNum(price), qty: 1 });
    setShowModal(false);
    basket.clearSelectedItems();
  }

  const addSimpleItem = () => {
    setShowModal(false);
    if (exsitingQty === qty) {
      return;
    }
    basket.addItem({ itemName, chinese, price, qty });
  }

  const listItems = () => {
    if (choices) {
      return <>{choices.map((choice, idx) => {
        return <ChoiceList key={idx} choice={choice} basket={basket} index={idx} lastIndex={choices.length-1}/>
      })}
        {(choices && choices[0].options.length > 20) && <div className={css(styles.backToTop)}><Button basic color='blue' onClick={() => document.getElementById("optionTop").scrollIntoView(true)}> Back to Top</Button></div>}
      </>
    }
  }

  const quantityView = <div >
    <div className={css(styles.quantityView)}>
      <div><Button circular basic color='blue' icon='minus' className={css(styles.quantityIcon)} onClick={() => qty > 1 && setQty(qty - 1)} /></div>
      <div className={css(styles.quantity)}>{qty}</div>
      <div><Button circular basic color='blue' icon='add' className={css(styles.quantityIcon)} onClick={() => setQty(qty + 1)} /></div>
    </div>
    {exsitingQty > 0 && <div className={css(styles.removeSection)}>
      <Button circular basic color='red' content='Remove from basket' className={css(styles.removeButton)} onClick={() => { basket.removeAllItemQuantity(itemName); setShowModal(false); }} /></div>}
  </div>

  const addToBasketButtonValue = () => {
    if (exsitingQty > 0) {
      if (exsitingQty !== qty) {
        return `Update  £${toFixed(price * qty)}`
      } else {
        return "OK"
      }
    } else {
      return `Add to basket  £${toFixed(price * qty)}`
    }
  }
  const numOfChoicesNeeded = () => {
    if (choices) {
      return choices.reduce((acc, choice) => acc + choice.numOfOptionsAllowed, 0);
    }
  }
  return (
    <div>
      <Segment id={itemName} className={css(styles.container)} onClick={() => addItem()}>
        <div className={css(styles.name)}>
          <Header as='h4' className={css(styles.header)}>
            {(basket.itemQuantity(itemName) > 0) && <Label className={css(styles.badge)} circular size="small" color="green">{!choices ? basket.itemQuantity(itemName) : basket.complexItemQuantity(itemName)}</Label>}
            {itemName}</Header>
          <div className={css(styles.desc)}> {description != "" && description}
          </div>
        </div>
        <div className={css(styles.price)}>
          <Header as='h4'>£ {toFixed(price)}</Header>
        </div>
      </Segment>
      <div className={css(styles.modal)}>
        <Modal closeIcon onClose={closeModal} open={showModal} className={css(styles.modal)}>
          <Modal.Content>
            <Header as='h2' textAlign="center" id="optionTop">{itemName}</Header>
            <Header as='h3' textAlign="center" >£ {price}</Header>
            <Header as='h5' textAlign="center" >{description}</Header>
            {choices ? listItems() : quantityView}
          </Modal.Content>
          <div className={css(styles.footer)}>
            {choices ?
              <input type="button" className={css(styles.addToBasketButton, numOfChoicesNeeded() > basket.totalSelected && styles.buttonDisabled)}
                value={`Add to basket  £${basket.selectItemPrice(price)}`}
                disabled={numOfChoicesNeeded() > basket.totalSelected}
                onClick={() => addComplexItem()} />
              : <input type="button" className={css(styles.addToBasketButton)}
                value={addToBasketButtonValue()}
                onClick={() => addSimpleItem()} />}
          </div>
        </Modal>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    margin: "auto",
    marginBottom: "8px",
    cursor: "pointer"
  },
  name: {
    textAlign: "left",
    flexGrow: "1",
    margin: "auto",
    paddingRight: "20px"
  },
  price: {
    margin: "auto",
    minWidth: "50px",
  },
  add: {
    margin: "auto"
  },
  desc: {
    textAlign: "left",
    fontFamily: "\"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif\""
  },
  header: {
    marginBottom: "2px"
  },
  button: {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "20px",
    margin: "4px 2px",
    cursor: "pointer",
  },
  modal: {
    maxWidth: "450px"
  },
  footer: {
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    color: "white",
    display: "flex",
    padding: "24px",
    boxShadow: "0 -2px 8px 0 rgba(0,0,0,.12)",
  },
  addToBasketButton: {
    backgroundColor: "#2185d0",
    border: "none",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "16px",
    borderRadius: "4px",
    width: "100%"
  },
  buttonDisabled: {
    opacity: 0.6,
    cursor: "not-allowed",
  },
  quantityView: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px"
  },
  quantity: {
    width: "50px",
    textAlign: "center",
    fontSize: "30px"
  },
  quantityIcon: {
    marginTop: "4px"
  },
  removeButton: {
    margin: "auto"
  },
  removeSection: {
    display: "flex"
  },
  badge: {
    marginLeft: 0,
    marginRight: "5px"
  },
  checkIcon: {
    width: "10px"
  },
  backToTop: {
    display: "flex",
    justifyContent: "center"
  }
});
export default observer(MenuItem);