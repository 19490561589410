import { observable, computed, autorun, toJS } from "mobx"
import { createTransformer } from 'mobx-utils'
import { toFixed } from '../utils/ultils'
import store from "store"

class Basket {
    @observable basket;
    @observable isDelivery;
    @observable selectedItems;
    @observable orderNotes;
    @observable shopClosed;

    constructor() {
        this.basket = [];
        this.selectedItems = [];
        this.isDelivery = true;
        this.shopClosed = false;
        this.orderNotes = "";
        let firstRun = true;
        autorun(() => {
            // on load check if there's an existing store on localStorage and extend the store
            if (firstRun) {
                const existingStore = store.get("basket")
                if (existingStore) {
                    this.restoreFromStorage(existingStore);
                }
            }

            // from then on serialize and save to localStorage
            store.set("basket", toJS(this.localStorage))
        })
        firstRun = false
    }

    @computed get localStorage() {
        return {
            basket: this.basket,
            isDelivery: this.isDelivery,
            orderNotes: this.orderNotes
        };
    }

    restoreFromStorage(data) {
        this.basket = data.basket;
        this.isDelivery = data.isDelivery;
        this.orderNotes = data.orderNotes;
    }

    @computed get addedItems() {
        return toJS(this.basket);
    }
    
    @computed get numOfItems() {
        return this.basket.reduce((acc, item) => acc + item.qty, 0);
    }

    @computed get hasItems() {
        return this.basket.length > 0;
    }

    @computed get deliveryFee() {
        // To-do
        return this.isDelivery ? parseFloat(process.env.REACT_APP_DELIVERY_CHARGE) : 0;
    }
    @computed get displayDeliveryFee() {
        return toFixed(this.deliveryFee);
    }

    @computed get serviceFee() {
        // To-do
        return parseFloat(process.env.REACT_APP_SERVICE_CHARGE);
    }
    @computed get displayServiceFee() {
        return toFixed(this.serviceFee);
    }

    @computed get subTotal() {
        return this.basket.reduce((acc, item) => acc + (item.price * item.qty), 0);
    }
    @computed get displaySubTotal() {
        return toFixed(this.subTotal);
    }
    @computed get metMinimum() {
        return !this.isDelivery || this.subTotal >= parseFloat(process.env.REACT_APP_MINIMUM_ORDER)
    }
    @computed get total() {
        return this.subTotal + this.deliveryFee + this.serviceFee;
    }

    @computed get totalNoService() {
        return this.subTotal + this.deliveryFee;
    }

    @computed get displayTotal() {
        return toFixed(this.total);
    }

    @computed get displayTotalNoService() {
        return toFixed(this.totalNoService);
    }

    @computed get itemQuantity() {
        return createTransformer(name => {
            const item = this.basket.find((i) => i.itemName === name);
            return item ? item.qty : 0
        })
    }

    @computed get complexItemQuantity() {
        return createTransformer(name => {
            const items = this.basket.filter((i) => i.itemName === name);
            return items ? items.length : 0
        })
    }

    @computed get selectItemQuantity() {
        return createTransformer(name => {
            const item = this.selectedItems.find((i) => i.name === name);
            return item ? item.quantity : 0
        })
    }
    @computed get selectItemPrice() {
        return createTransformer(price => {
            return toFixed(price + this.selectedItems.reduce((acc, selected) => acc + (selected.additionalCost * selected.quantity), 0));
        })
    }
    @computed get selectItemPriceNum() {
        return createTransformer(price => {
            return price + this.selectedItems.reduce((acc, selected) => acc + (selected.additionalCost * selected.quantity), 0);
        })
    }

    @computed get totalSelected() {
        return this.selectedItems.reduce((acc, selected) => acc + selected.quantity, 0);
    }

    setShopClosed(closed) {
        this.shopClosed = closed;
    }
    addItem(item) {
        var index = this.basket.find((i) => i.itemName === item.itemName && i.price === item.price);
        if (index === undefined) {
            this.basket.replace([...this.basket, item]);
        }
        else {
            index.qty = item.qty;
        }
    }

    removeItem(item) {
        var food = this.basket.find((i) => i.itemName === item.itemName && i.price === item.price);
        if (food.qty > 1) {
            food.qty = food.qty - 1;
        }
        else {
            this.basket.remove(food);
        }
        // this.basket.remove(this.basket.find((i) => i.itemName === item.itemName && i.price === item.price));
    }

    removeAllItemQuantity(name) {
        this.basket.remove(this.basket.find((i) => i.itemName === name));
    }

    getItemQuantity(name) {
        const item = this.basket.find((i) => i.itemName === name);
        return item ? item.qty : 0
    }

    setDelivery(isDelivery) {
        this.isDelivery = isDelivery;
    }

    selectItem(selected) {
        const exist = this.selectedItems.find((i) => i.name === selected.name);
        if (exist) {
            ++exist.quantity;
        } else {
            this.selectedItems.replace([...this.selectedItems, { name: selected.name, chinese: selected.chinese,additionalCost: selected.additionalCost, quantity: 1 }]);
        }
    }
    removeSelectedItem(selected) {
        const exist = this.selectedItems.find((i) => i.name === selected);
        if (exist.quantity > 1) {
            --exist.quantity;
        }
        else {
            this.selectedItems.remove(exist);
        }
    }
    clearSelectedItems() {
        this.selectedItems.replace([]);
    }
    addSelectedItemsToBasket(item) {
        item.subItems = toJS(this.selectedItems);
        this.basket.replace([...this.basket, item]);
    }
    setOrderNotes(notes) {
        this.orderNotes = notes;
    }
}

const basket = new Basket();
export default basket;
