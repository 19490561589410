import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import HomePage from './components/home';
import CheckoutSuccess from './components/checkout/checkoutSuccess'
import CheckoutFailure from './components/checkout/checkoutFailure'
import Checkout from './components/checkout/checkout'
import MobileBasket from './components/basket/mobileBasket'

const App = () => {
    return (
      <div className="App">
      <Router>
        <div>
          <Route name="home" exact path="/" component={HomePage} />
          <Route name="home" exact path="/menu" component={HomePage} />
          <Route name="home" exact path="/home" component={HomePage} />
          <Route name="basket" exact path="/basket" component={MobileBasket} />
          <Route name="checkout" exact path="/checkout" component={Checkout} />
          <Route name="checkoutSuccess" exact path="/checkout/checkoutSuccess" component={CheckoutSuccess} />
          <Route name="checkoutFailure" exact path="/checkout/checkoutFailure" component={CheckoutFailure} />
          {/* <Route name="login" exact path="/login" component={Login} />
          <Route name="register" exact path="/register" component={Register} /> */}
        </div>
      </Router>
      </div>
    );
}

export default App;
