import React from 'react';
import { List, Label, Icon } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'
import { toFixed } from '../../utils/ultils'

const BasketItem = (props) => {
  const { basket, itemName, price, qty, subItems, viewOnly } = props;

  const displaySubItems = () => {
    if (subItems) {
      return <List bulleted className={css(styles.list)}>
        {subItems.map((item) => {
          return <List.Item key={item.name}>{item.name} <b>{item.quantity > 1 && "x" + item.quantity}</b></List.Item>
        })}
      </List>
    }
  }
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.line)}>
        {!viewOnly && <Label className={css(styles.button)} size="small" color="red" basic onClick={() => basket.removeItem({ itemName, price })}><Icon className={css(styles.deleteIcon)} name='minus' /></Label>}
        <div className={css(styles.qty)}>
            {qty > 1 && 'x' + qty}</div>
        <div className={css(styles.name)}>
           {itemName}
        </div>

        <div className={css(styles.price)}>
          £ {toFixed(price * qty)}
        </div>
      </div>
      <div className={css(styles.subLine)}>
        {displaySubItems()}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: "14px",
    padding: "0px 20px",
    fontSize: "15px"
  },
  button: {
    padding: "5px 6px",
    cursor: "pointer",
    maxHeight: "23px",
    margin: "auto",
    marginRight: "10px"
  },
  deleteIcon: {
    margin: 0
  },
  name: {
    display: "flex",
    margin: "auto",
    flexGrow: 1,
    marginRight: "10px",
    textAlign: "left",
    flexWrap: "wrap"
  },
  price: {
    margin: "auto",
    minWidth: "60px",
    textAlign: "right"
  },
  qty: {
    margin: "auto",
    marginLeft: "0px",
    marginRight: "10px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center"
  },
  line: {
    display: "flex",
    flexGrow: 1,
  },
  subLine: {
    textAlign: "left",
    marginLeft: "50px",
  },
  list: {
    fontSize: "14px"
  }
}
);
export default observer(BasketItem);