import React, { useState } from 'react';
import { Header, Modal, Divider } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react';
import BasketContent from '../basket/content'
import Summary from '../basket/summary'

const BasketPopupView = (props) => {
    const { basket } = props;
    const [showModal, setShowModal] = useState(false);

    return (
        <Modal size="mini"
            closeIcon
            trigger={<Header textAlign='left' as='h4' className={css(styles.total)}>
                <span className={css(styles.viewBasket)} onClick={() => setShowModal(true)}>View Basket</span></Header>}
            onClose={() => setShowModal(false)}
            open={showModal}>

            <Modal.Content >
                <Header as='h2' textAlign='center' >Basket</Header>
                <Divider />
                {basket.orderNotes !=="" && <Header as='h5' textAlign='left' className={css(styles.orderNoteHeader)}>Order Note</Header>}
                {basket.orderNotes !=="" && <p>{basket.orderNotes}</p>}
                <BasketContent basket={basket} viewOnly={true} />
                <Divider />
                <Summary basket={basket} />
            </Modal.Content>
        </Modal>
    );
}

const styles = StyleSheet.create({
    total: {
        display: "flex",
        margin: "5px",
        marginBottom: "15px"
    },
    totalText: {
        flexGrow: 1
    },
    viewBasket: {
        color: "#2185d0",
        cursor: "pointer"
    },
    orderNoteHeader: {
        marginBottom: 0
    },
}
);
export default observer(BasketPopupView);