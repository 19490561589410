import React, { useState, useEffect } from 'react';
import { Header, Icon, List, Divider, Message, Dropdown, TransitionablePortal } from 'semantic-ui-react';
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react';
import { toFixed } from '../../utils/ultils'
import _ from 'lodash'

const ChoiceList = (props) => {
    const { basket, index, lastIndex } = props;
    const { numOfOptionsAllowed, options } = props.choice;
    const [numSelected, setNumSelected] = useState(0);
    const [showMessage, setShowMessage] = useState({ show: false, text: "" });
    const [search, setSearch] = useState(search);

    useEffect(() => {
        if (options.length === 1) {
            select(options[0]);
        }
      },[]);
    const select = (option) => {
        if (numSelected < numOfOptionsAllowed) {
            basket.selectItem(option);
            setNumSelected(numSelected + 1);
            if(options.length >1 ){
                setShowMessage({ show: true, text: option.name + " selected" });
            }
            if (numSelected === numOfOptionsAllowed - 1 && index !== lastIndex) {
                document.getElementById(index + 1).scrollIntoView(true);
            }
        } else {
            if(options.length >1 ){
                setShowMessage({ show: true, text: "You've already selected " + numOfOptionsAllowed + " item(s)" });
            }
        }
        setTimeout(() => {
            setShowMessage({ show: false, text: "" });
        }, 2000);
    }
    const remove = (name) => {
        if (basket.selectedItems.find((i) => i.name === name)) {
            basket.removeSelectedItem(name);
            setNumSelected(numSelected - 1);
            setShowMessage({ show: false, text: "" });
        }
    }
    const listItems = () => {
        if (options) {
            const sortedOptions = options.sort((a, b) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 });
                return sortedOptions.map((option, idx) => {
                    const displayName = option.name + " " + (option.additionalCost > 0 ? "+ £" + toFixed(option.additionalCost) : "");
                    return <List.Item key={idx} className={css(styles.listItems)}>
                        <List.Content className={css(styles.section)}>
                            <div className={css(styles.headerClickable)} onClick={() => select(option)}>
                                <Header as='h4' color="grey" className={css(styles.header)}>
                                    {basket.selectItemQuantity(option.name) > 0 && <span className={css(styles.badge)}><b>{basket.selectItemQuantity(option.name)}</b></span>}
                                    <span className={css(styles.name)} >{displayName}</span>
                                </Header>
                            </div>
                            {basket.selectItemQuantity(option.name) > 0 && sortedOptions.length > 1 && <Icon size="large" className={css(styles.removeIcon)} name='trash alternate outline' color='red' onClick={() => remove(option.name)} />}
                        </List.Content>
                    </List.Item>
                });
        }
    }
    const handleResultSelect = (e, { value }) => {
        if (numSelected < numOfOptionsAllowed) {
            select(options.filter((o) => o.name === value)[0]);
        }
        setSearch("");
    };

    return (
        <> <Divider horizontal>
            {options.length > 1 && <Header as='h4'>
                Choose {numOfOptionsAllowed}
            </Header>}
        </Divider>
            {options.length > 10 &&
                <div>
                    <Dropdown
                        className={css(styles.search)}
                        placeholder='Search for your favourite dishes'
                        search
                        selection
                        fluid
                        value={search}
                        options={options.map(i => ({ key: i.name, value: i.name, text: i.name }))}
                        onChange={handleResultSelect}
                    />
                </div>}
            <TransitionablePortal open={showMessage.show}>
                <Message color="olive" size='small' style={{ left: '0', right: '0',margin: "auto", width: "300px", position: 'fixed', top: '0px', zIndex: 1000 }}>
                    <Message.Header style={{ textAlign: "center" }}>{showMessage.text}</Message.Header>
                </Message>
            </TransitionablePortal>
            <List selection verticalAlign='middle' id={props.index}>
                {listItems()}
            </List>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        display: "flex",
    },
    headerClickable: {
        flexGrow: 1,
        margin: "auto",
        userSelect: "none",
        padding: "7px"
    },
    section: {
        display: "flex",
        borderBottom: "1px dashed #eaeaea",
    },
    name: {
        margin: "auto",
        marginLeft: 0,
    },
    badge:
    {
        padding: "3px 8px",
        borderRadius: "14px",
        background: "#4caf50",
        color: "white",
        fontSize: ".85714rem",
        fontWeight: "700",
        lineHeight: 1.3,
        fontFamily: "\"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif\"",
        marginRight: "8px",
        height: "22px"
    },
    listItems: {
        padding: "0px"
    },
    removeIcon: {
        marginTop: "7px"
    }
});
export default observer(ChoiceList);