import React from 'react';
import { observer } from 'mobx-react'
import BasketItem from './basketItem'

const BasketContent = (props) => {
    const displayBasketItem = () => {
        return props.basket.addedItems.map((item, index) => {
            return <BasketItem key={index} itemName={item.itemName} price={item.price} basket={props.basket} qty={item.qty} subItems={item.subItems} viewOnly={props.viewOnly}/>
        });
    }
    return (
        <div>
            {displayBasketItem()}
        </div>
    );
}

export default observer(BasketContent);