import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react'
import { Header, Dropdown, Menu } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const CatMenu = (props) => {
    const { categories, hide } = props;
    const [width, setWidth] = useState(window.innerWidth);
    const [numOfCatsToDisplay, setNumOfCatsToDisplay] = useState(7);

    useEffect(() => {
        if (width > 1024) {
            setNumOfCatsToDisplay(8);
        }
        window.addEventListener('resize', handleWindowSizeChange);
        return function cleanup() {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    });
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    const dropDownScroll = (e, { value }) => {
        document.getElementById(value).scrollIntoView(true);
    }
    const displayCatMenu = () => {
        if (categories) {
            if (width > 1023) {
                let dropDownOptions = [];
                if (categories.length > numOfCatsToDisplay) {
                    for (let i = numOfCatsToDisplay; i < categories.length; i++) {
                        dropDownOptions.push({
                            key: categories[i],
                            text: categories[i],
                            value: categories[i],
                        })
                    }
                }
                return <Menu pointing secondary borderless className={css(styles.autoScroll, hide && styles.hide)}>
                    {categories.map((cat, idx) => {
                        return <Fragment key={cat}>
                            {idx < numOfCatsToDisplay && <Menu.Item className={css(styles.menuItem)} key={cat}> 
                                <Link activeClass={css(styles.selected)} to={cat} spy={true} offset={-50}>
                                    <Header as="h4" className={css(styles.header)}>{cat}</Header>
                                </Link></Menu.Item>}
                            {idx === numOfCatsToDisplay && <Header as="h4" className={css(styles.moreSection)}>
                                <Menu.Menu position='right' >
                                    <Dropdown
                                        inline
                                        scrolling
                                        trigger={<span>More</span>}
                                        options={dropDownOptions}
                                        onChange={dropDownScroll}
                                    /></Menu.Menu>
                            </Header>}
                        </Fragment>
                    })}
                </Menu>
            } else {
                return <Menu pointing secondary borderless className={css(styles.autoScroll, hide && styles.hide)}>
                    {categories.map((cat) => {
                        return <Menu.Item key={cat}>
                            <Link activeClass={css(styles.selected)} to={cat} spy={true} offset={-50}>
                                <Header as="h4" className={css(styles.header)}>{cat}</Header>
                            </Link>
                        </Menu.Item>
                    })}
                </Menu>
            }
        }
    }
    return displayCatMenu();

}
const styles = StyleSheet.create({
    header: {
        userSelect: "none",
        cursor: "pointer",
    },
    item: {
        padding: "0 15px",
        margin: "5px 10px",
        cursor: "pointer",
        border: "none",
        minWidth: "50px",
    },
    menuItem: {
        // margin: "auto",
    },
    autoScroll: {
        display: "flex",
        margin: "auto",
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
        paddingLeft: "50px",
        paddingRight: "100px",
        width: "100%",
        justifyContent: "center",
        '@media (max-width: 1023px)': {
            justifyContent: "left",
            overflowY: "auto",
            '::-webkit-scrollbar': {
                display: 'none',
            },
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        userSelect: "none",
        height: "50px",
    },
    selected: {
        borderBottom: "2px solid black",
    },
    moreSection: {
        padding: "12px 16px",
        marginTop: "5px",
        minWidth: "50px"
    },
    hide: {
        display: 'none'
    }
});
export default observer(CatMenu);