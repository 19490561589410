import React from 'react';
import { Header, Icon, Divider } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import BasketContent from './content'
import Summary from './summary'
import { observer } from 'mobx-react'
import { useHistory } from "react-router-dom";
import DeliveryMethod from './deliveryMethod'
import BasketStore from '../../states/basket'
import OrderNotesModal from './orderNotesModal'

const MobileBasket = () => {
    const minimum = parseFloat(process.env.REACT_APP_MINIMUM_ORDER);
    const history = useHistory();
    const redirect = () => {
        if(BasketStore.metMinimum && !BasketStore.shopClosed) {
            history.push("/checkout");
        }
    }

    return (
        <div>
            <div className={css(styles.container)}>
                <div className={css(styles.headerWrapper)}>
                    <Header as='h2' textAlign='center' >
                        <Icon name='arrow left' size='big' floated='left' className={css(styles.back)} onClick={history.goBack} />
                Your Order</Header>
                </div>
                <DeliveryMethod basket={BasketStore} />
                {BasketStore.hasItems && <OrderNotesModal basket={BasketStore} />}
                {BasketStore.hasItems && <BasketContent basket={BasketStore} />}
                {BasketStore.hasItems && <Divider />}
                {BasketStore.hasItems && <Summary basket={BasketStore} />}
                {!BasketStore.hasItems && <Header as='h5' className={css(styles.header)}>There are no items in your basket</Header>}
            </div>
            <div className={css(styles.footer)}>
                <input type="button" className={css(styles.button, (!BasketStore.hasItems || BasketStore.shopClosed || !BasketStore.metMinimum) && styles.buttonDisabled)} value={BasketStore.shopClosed ? "Sorry we are closed": 
                                                                                                                              !BasketStore.metMinimum ? `Minimum Order: £${minimum}`:
                                                                                                                              "Checkout"}
                    disabled={!BasketStore.hasItems || BasketStore.shopClosed || !BasketStore.metMinimum}
                    onClick={() => redirect()} />
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    back: {
        position: "absolute",
        left: "20px",
    },
    container: {
        paddingBottom: "120px",
    },
    headerWrapper: {
        boxShadow: "0 -2px 8px 0 rgba(0,0,0,.12)",
        padding: "16px",
        backgroundColor: "white",
    },
    buttonGroup: {
        width: "100%",
        padding: "16px"
    },
    button: {
        backgroundColor: "#2185d0",
        border: "none",
        color: "white",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        fontWeight: "bold",
        cursor: "pointer",
        borderRadius: "4px",
        padding: "16px",
        borderRadius: "4px",
        width: "100%"
    },
    buttonDisabled: {
        opacity: 0.6,
        cursor: "not-allowed",
    },
    footer: {
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        color: "white",
        display: "flex",
        padding: "24px",
        boxShadow: "0 -2px 8px 0 rgba(0,0,0,.12)",
    }
}
);
export default observer(MobileBasket);