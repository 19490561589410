import React, { useState } from 'react';
import { Button } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'

const DeliveryMethod = (props) => {
    const { basket } = props;
    const [isDelivery, setIsDelivery] = useState(basket.isDelivery);
    return (
        <div>
            <Button.Group className={css(styles.buttonGroup)} size='large'>
                <Button className={css(styles.button,isDelivery && styles.buttonColour)}  onClick={() => {basket.setDelivery(true);setIsDelivery(true)}}>Delivery <br /><span className={css(styles.notes)}>30-90 mins</span></Button>
                <Button.Or />
                <Button className={css(styles.button,!isDelivery && styles.buttonColour)} onClick={() => {basket.setDelivery(false);setIsDelivery(false)}}>Collection <br /><span className={css(styles.notes)}>10-30 mins</span></Button>
            </Button.Group>
        </div>
    );
}

const styles = StyleSheet.create({
    button: {
        padding: "8px"
    },
    buttonColour: {
        background: "#2185d0",
        color: "white"
    },
    buttonGroup: {
        width: "100%",
        padding: "16px"
    },
    notes: {
        fontSize: "10px"
    }
});
export default observer(DeliveryMethod);