import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import NavBar from '../headerComponents/navBar'
import Footer from '../footer'
import { Button, Header } from 'semantic-ui-react'

const CheckoutFailure = () => {
    return (
        <div>
            <NavBar />
            <div className={css(styles.container)}>
                <Header as="h1">Something went wrong - please call us to check if your order has been placed</Header>
                <Header as="h1" color="red">0114 286 3558</Header>
                <Button primary size='huge' icon className={css(styles.button)} onClick={() => { document.location.href = "/"; }}>
                    Back
            </Button>
            </div>
            <Footer />
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: "auto",
        padding: '50px'
    },
    button: {
        width: "200px"
    }
}
);

export default CheckoutFailure;