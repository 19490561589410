import React, { useMemo } from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './css/cardSection.css'
import { observer } from 'mobx-react'


const useOptions = (props) => {
    const options = useMemo(
      () => ({
        hidePostalCode: props.isDelivery,
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
        }
      })
    );

    return options;
  };

function CardSection(props) {
  return (
    <div>
        <CardElement options={useOptions(props)} />
    </div>
  );
};

export default observer(CardSection);