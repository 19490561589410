import React, { useState } from 'react';
import { Header, Modal, TextArea } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'

const OrderNotesModal = (props) => {
    const { basket } = props;
    const [notes, setNotes] = useState("");
    const [showModal, setShowModal] = useState(false);

    return (
        <Modal size="mini"
            closeIcon
            trigger={<Header as='h5' className={css(styles.header)} color="blue" textAlign="center" onClick={() => setShowModal(true)}>Order notes</Header>}
            onClose={() => setShowModal(false)}
            open={showModal}>
            <Modal.Content >
                <Header as='h1' textAlign="center" >Order Notes</Header>
                <Header as='h5' textAlign="center" color="grey">Don't leave allergy information here. Always contact us if you have an allergy, contact details can be found under "Contact us" tab</Header>
                <TextArea className={css(styles.textArea)} maxLength="500" onChange={(e, { value }) => setNotes(value)} defaultValue={basket.orderNotes} placeholder="Notes for the kitchen e.g. no vegetables please" />
            </Modal.Content>
            <div className={css(styles.footer)}>
                <input type="button" className={css(styles.saveButton)}
                    value="Save"
                    onClick={() => { basket.setOrderNotes(notes); setShowModal(false) }} />
            </div>
        </Modal>
    );
}

const styles = StyleSheet.create({
    saveButton: {
        backgroundColor: "#2185d0",
        border: "none",
        color: "white",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        fontWeight: "bold",
        cursor: "pointer",
        borderRadius: "4px",
        padding: "16px",
        borderRadius: "4px",
        width: "100%"
    },
    textArea: {
        width: "100%",
        minHeight: "100px"
    },
    header: {
        marginTop: "0px",
        marginBottom: "5px",
        cursor: "pointer"
    },
    footer: {
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        color: "white",
        display: "flex",
        padding: "24px",
        boxShadow: "0 -2px 8px 0 rgba(0,0,0,.12)",
    },
}
);
export default observer(OrderNotesModal);