import React, {useEffect} from 'react';
import { StyleSheet, css } from 'aphrodite';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react'
import CheckoutForm from './checkoutForm';
import '../base.css'
import {Segment, Icon, Header} from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import Footer from '../footer';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
 const publicKey = process.env.REACT_APP_STRIP_PUBLIC_API_KEY_LIVE;
// const publicKey = process.env.REACT_APP_STRIP_PUBLIC_API_KEY;
const stripePromise = loadStripe(publicKey);
const Checkout = () => {
    const history = useHistory();
    useEffect(() => {
          window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <div className={css(styles.headerWrapper)}>
            <Header as='h2' textAlign='center' > 
                <Icon name='arrow left' size='big' floated='left' className={css(styles.back)} onClick={history.goBack}/>
                Your Details
            </Header>
            </div>
            <div className={css(styles.container)}>
                <Segment>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>
                </Segment>
            </div>
            <Footer />
            </div>
        );
};

const styles = StyleSheet.create({
    container: {
        margin: "auto",
        marginTop: "20px",
        paddingLeft: "14px",
        paddingRight: "14px",
        maxWidth: "450px",
        marginBottom: "100px",
    },
    headerWrapper: {
        boxShadow: "0 -2px 8px 0 rgba(0,0,0,.12)",
        padding: "16px",
        backgroundColor: "white",
        height:"58px"
    },
    back: {
        // margin:"auto",
        position: "absolute",
        //  display: "block",
        left: "20px",
        // marginTop: "10px"
        // margin: 0
        // padding: "16px 4px"
    },
  }
);
export default observer(Checkout);