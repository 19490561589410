import React from 'react';
import { Header, Icon } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'
import { useHistory } from "react-router-dom";

const BasketBar = (props) => {
    const { basket, } = props;
    const history = useHistory();
    const redirect = () => {
        history.push("/basket");
    }
    return (
        <div className={css(styles.container)} onClick={() => redirect()}>
            <div className={css(styles.content)}>
                <div className={css(styles.icon)}>
                    <Icon name='shopping basket' size='large'></Icon>
                    <span className={css(styles.badge)}><b>{basket.numOfItems}</b></span>
                </div>
                <div>
                    <Header as='h5' className={css(styles.total)}>£ {basket.displaySubTotal}</Header>
                    <p>Go to checkout </p>
                </div>
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#2185d0",
        color: "white",
        height: "64px",
        display: "flex",
        padding: "16px",
    },
    icon: {
        margin: "auto",
        marginRight: "22px",
        marginLeft: "3px",
        position: "relative"
    },
    content: {
        margin: "auto",
        marginLeft: 0,
        display: "flex"
    },
    total: {
        marginBottom: 0,
        textAlign: "left",
        color: "white"
    },
    badge:
    {
        position: "absolute",
        top: "-8px",
        right: "-8px",
        padding: "0px 5px",
        borderRadius: "50%",
        background: "#4caf50",
        color: "white",
        fontSize: ".85714rem",
        fontWeight: "500",
        lineHeight: 1.5
    }
}
);
export default observer(BasketBar);