import React from 'react';
import { Header } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'

const Summary = (props) => {
    return (
        <div className={css(styles.container)}>
            <div className={css(styles.line)}><div className={css(styles.lable)}><b>Subtotal</b>: </div><b>£ {props.basket.displaySubTotal}</b></div>
            {props.basket.isDelivery && <div className={css(styles.line)}><div className={css(styles.lable)}>Delivery: </div>£ {props.basket.displayDeliveryFee}</div>}
            <div className={css(styles.line)}><div className={css(styles.lable)}>Service(card only): </div>£ {props.basket.displayServiceFee}</div>
            <div className={css(styles.total)}><div className={css(styles.lable)}><Header as='h4'>Total: </Header> </div>
                <Header as='h4' className={css(styles.header)}>£ {props.basket.displayTotal}</Header></div>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: "0px 20px",
        textAlign: "left",
        fontSize: "15px"
    },
    line: {
        display: "flex",
        margin: "auto"
    },
    total: {
        display: "flex",
        margin: "auto",
        marginTop: "20px"
    },
    lable: {
        flexGrow: 1,
    },
    header: {
        marginTop: "0px",
    }
}
);
export default observer(Summary);