import React from 'react';
import { Header } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react';
import DeliveryMethod from './deliveryMethod'
import OrderNotesModal from './orderNotesModal'


const BasketHeader = (props) => {
  return (
    <div className={css(styles.container)}>
      <Header as='h3' className={css(styles.header)}>Your Order</Header>
      <DeliveryMethod basket={props.basket} />
      {props.basket.hasItems && <OrderNotesModal basket={props.basket} />}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: "left",
  },
  header: {
    marginBottom: 0,
    textAlign: "center"
  },
  radioGroup: {
    marginTop: "30px",
    marginLeft: "16px"
  },
  radioLabel: {
    marginLeft: "30px"
  },
});
export default observer(BasketHeader);