import React, { useState } from 'react';
import MenuItem from './menuItem.js';
import { Header, Icon, Divider } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'

const Category = (props) => {
  const [name] = useState(props.name);
  const [chevron, setChevron] = useState("chevron down");
  const [itemContainer, setItemContainer] = useState(styles.container);
  const [descriptionText, setdescriptionText] = useState(styles.description);

  const itemsInCategory = () => {
    const menu = props.menu;
    if (menu) {
      const items = menu.filter((item) => item.category === name).sort((a,b)=> {return a.itemName < b.itemName ? -1 : a.itemName > b.itemName ? 1 : 0});
      return items.map((item, index) => <MenuItem key={index} itemName={item.itemName} chinese={item.chinese}price={item.price} description={item.itemDescription} choices={item.choices} basket={props.basket} />)
    }
  }

  const hide = () => {
    if (chevron === "chevron down") {
      setChevron("chevron up");
      setItemContainer(styles.containerHide);
      setdescriptionText(styles.descriptionHide);
    } else {
      setChevron("chevron down");
      setItemContainer(styles.container);
      setdescriptionText(styles.description);
    }
  }

  function getDescription() {
    const item = props.menu.find((i) => i.category === name);
    return item.description;
  }
  return (
    <div id={name} className={css(styles.wrapper)}>
      <div className={css(styles.headerSection)} onClick={() => hide()}>
        <Header as='h3' className={css(styles.header)}>{name}</Header>
        <Icon name={chevron} color="grey" size='large' className={css(styles.accordion)} />
      </div>
      <div className={css(descriptionText)}><Header as='h5' color="grey" className={css(styles.subHeader)}>{getDescription()}</Header> </div>

      <div className={css(itemContainer)}>
        {itemsInCategory()}
      </div>
      <Divider className={css(styles.divider)} />
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    '@media (min-width: 768px)': {
      width: "455px",
    },
    margin: "auto",
    color: "#333",
    scrollMarginTop: "50px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  container: {
    paddingBottom: "16px"

  },
  containerHide: {
    display: "none",
  },
  segment: {
    display: "flex",
    cursor: "pointer",
    marginBottom: "0px"
  },
  header: {
    flexGrow: 1,
    marginBottom: "0px",
    textAlign: "left"
  },
  subHeader: {
    marginBottom: "0px",
    textAlign: "left",
    paddingLeft: "5px",
  },
  headerSection: {
    margin: "auto",
    display: "flex",
    padding: "15px 5px",
    cursor: "pointer"
  },
  accordion: {
    margin: "auto",
    marginLeft: "0px",
    marginRight: "0px"
  },
  description: {
    fontFamily: "\"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif\"",
    marginBottom: "8px"
  },

  descriptionHide: {
    display: "none"
  },
  divider: {
    margin: "0px"
  }
});

export default observer(Category);

