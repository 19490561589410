import React from 'react';
import { Header, Segment, Divider } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import BasketHeader from './header'
import BasketContent from './content'
import Summary from './summary'
import { observer } from 'mobx-react'
import { useHistory } from "react-router-dom";

const BasketComponent = (props) => {
  const { basket } = props;
  const minimum = parseFloat(process.env.REACT_APP_MINIMUM_ORDER);
  const history = useHistory();
  const redirect = () => {
    if(basket.metMinimum && !basket.shopClosed) {
      history.push("/checkout");
    }
  }
  return (
    <div className={css(styles.basket)}>
      <Segment className={css(styles.segment)}>
        <BasketHeader basket={basket} />
        {basket.hasItems && <Divider className={css(styles.divider)} />}
        {basket.hasItems && <BasketContent basket={basket} />}
        {basket.hasItems && <Divider />}
        {basket.hasItems && <Summary basket={basket} />}
        {!basket.hasItems && <Header as='h5' className={css(styles.header)}>There are no items in your basket</Header>}
      </Segment>
      <input type="button" className={css(styles.button, (!basket.hasItems || basket.shopClosed || !basket.metMinimum) && styles.disabled)} value={basket.shopClosed ? "Sorry we are closed": 
                                                                                                                              !basket.metMinimum ? `Minimum Order: £${minimum}`:
                                                                                                                              "Checkout"}
        onClick={() => redirect()} disabled={(!basket.hasItems || basket.shopClosed || !basket.metMinimum)} />
    </div>
  );
}

const styles = StyleSheet.create({
  basket: {
    marginLeft: "50px",
    marginTop: "10px",
    marginBottom: "10px",
    // // position: "sticky",
    // // top: "50px",
    minWidth: "350px",
    maxWidth: "400px",
    '@media (max-width: 800px)': {
      width: "250px",
      marginLeft: "20px",
    },
    '@media (max-width: 1023px)': {
      display: 'none',
    },

  },
  segment: {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginBottom: "10px"
  },
  header: {
    textAlign: "left",
    paddingLeft: "20px"
  },
  button: {
    backgroundColor: "#2185d0",
    border: "none",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "4px 2px",
    cursor: "pointer",
    width: "300px",
    height: "45px",
    width: "100%",
    '@media (max-width: 800px)': {
      width: "250px",
    },
  },
  disabled: {
    opacity: 0.6,
    cursor: "not-allowed",
  },
  divider: {
    marginTop: 0
  }
}
);
export default observer(BasketComponent);